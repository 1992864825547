<template>
  <section>
    <section class="top">
      <div>
        <p class="recp_title">
          Receipt
        </p>
        <p class="recp_subtitle">
          Create New Receipt
        </p>
      </div>
      <div>
        <p class="recp_subtitle">
          {{ stdName ? stdName : 'Student Name' }}
        </p>
        <p class="recp_subtitle">
          {{ stdClass ? `Class: ${stdClass}`: 'Student Class' }}
        </p>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="save"
        >
          Save
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="grid_2">
        <VForm
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <!-- Receipt Details -->
          <VCard class="recp_card">
            <p class="recp_main_title pb-3">
              Basic Details
            </p>
            <div class="recp_content">
              <div class="span3">
                <VTextField
                  v-model="stdName"
                  :rules="[rules.required]"
                  :hide-details="true"
                  class="recp_main_txt"
                  label="Name"
                  placeholder="Camila Cabello"
                />
              </div>
              <div>
                <VTextField
                  v-model="admNoInput"
                  :rules="[rules.required]"
                  :mask="admNoFormat"
                  :hide-details="true"
                  class="recp_main_txt"
                  prefix="FLA"
                  placeholder="000-1920"
                  label="Admission Number"
                />
              </div>
              <div>
                <VSelect
                  v-model="stdClass"
                  :rules="[rules.required]"
                  :items="selectClass"
                  :hide-details="true"
                  class="recp_main_txt"
                  label="Class"
                  placeholder="PG/5/5-A"
                />
              </div>
              <!-- DATE PICKER -->
              <div>
                <VDialog
                  ref="recpDateData"
                  v-model="recpDateModal"
                  :return-value.sync="recpDateDef"
                  lazy
                  full-width
                  persistent
                  width="290"
                >
                  <VTextField
                    slot="activator"
                    :value="recpDate"
                    :hide-details="true"
                    label="Date Of Issue"
                    class="recp_main_txt"
                    readonly
                  />
                  <VCard class="elevation-20 picker_card">
                    <VDatePicker
                      v-model="recpDateDef"
                      color="primary"
                      no-title
                      scrollable
                      class="elevation-0 pt-2"
                    />
                    <VCardActions>
                      <VSpacer />
                      <div class="pt-2 pr-2 pl-0 pb-2">
                        <div>
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="recpDateModal = false"
                          >
                            Close
                          </VBtn>
                          <VBtn
                            class="ma-0"
                            color="primary"
                            flat
                            round
                            depressed
                            @click="$refs.recpDateData.save(recpDateDef)"
                          >
                            Save
                          </VBtn>
                        </div>
                      </div>
                    </VCardActions>
                  </VCard>
                </VDialog>
              </div>
              <!-- DATE PICKER -->
              <div>
                <VTextField
                  v-model="stdPNumber"
                  :hide-details="true"
                  :rules="[rules.required, rules.phoneNumber, rules.phoneNumberLimit]"
                  class="recp_main_txt"
                  maxlength="10"
                  prefix="+91"
                  label="Phone No.(Primary)"
                  placeholder="0123456789"
                />
              </div>
              <div>
                <VSelect
                  v-model="recpMonth"
                  :rules="[rules.required]"
                  :items="selectMonths"
                  :hide-details="true"
                  :placeholder="selectMonths[0]"
                  class="recp_main_txt"
                  label="Month"
                />
              </div>
              <div>
                <VTextField
                  v-model="session"
                  :rules="[rules.required]"
                  :mask="sessionFormat"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="2019-2020"
                  label="Session"
                />
              </div>
            </div>
          </VCard>
        </VForm>
        <VForm
          ref="form1"
          v-model="valid"
          lazy-validation
        >
          <!-- FEES  -->
          <VCard class="recp_card">
            <p class="recp_main_title pb-3">
              Fees Details
            </p>
            <div class="recp_content">
              <div>
                <VTextField
                  v-model.number="fsTuition"
                  :rules="[rules.required, rules.phoneNumber]"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="1000"
                  label="Tuition Fee"
                  type="number"
                />
              </div>
              <div>
                <VTextField
                  v-model.number="fsLate"
                  :rules="[rules.required, rules.phoneNumber]"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="1000"
                  label="Late Fee"
                  type="number"
                />
              </div>
              <div>
                <VTextField
                  v-model.number="fsBankCharge"
                  :rules="[rules.required, rules.phoneNumber]"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="1000"
                  label="Bank Charge"
                  type="number"
                />
              </div>
              <div>
                <VTextField
                  v-model.number="fsMisc"
                  :rules="[rules.required, rules.phoneNumber]"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="1000"
                  label="Miscellaneous"
                  type="number"
                />
              </div>
              <div>
                <VTextField
                  v-model="fsTotal"
                  :hide-details="true"
                  class="recp_main_txt"
                  label="Total"
                  readonly
                />
              </div>
              <div>
                <VSelect
                  v-model="fsMethod"
                  :rules="[rules.required]"
                  :items="selectMethod"
                  :hide-details="true"
                  class="recp_main_txt"
                  label="Payment Method"
                  placeholder="Cash / Cheque"
                />
              </div>
              <div class="span3">
                <VTextField
                  v-model="fsInTxt"
                  :rules="[rules.required]"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="Two Thousand Three Hundred Fifty"
                  label="Amount In Word"
                />
              </div>
            </div>
          </VCard>
        </VForm>
        <!-- CHEQUE INFO  -->
        <VSlideYTransition>
          <VCard
            v-if="fsMethod === 'Cheque'"
            class="recp_card span2only"
          >
            <p class="recp_main_title pb-3">
              Cheque Details
            </p>
            <div class="recp_content_cq">
              <div>
                <VTextField
                  v-model="cqNumber"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="605100"
                  label="Cheque Number"
                />
              </div>
              <div>
                <!-- DATE PICKER -->
                <div>
                  <VDialog
                    ref="cqDateData"
                    v-model="cqDateModal"
                    :return-value.sync="cqDateDef"
                    lazy
                    full-width
                    persistent
                    width="290"
                  >
                    <VTextField
                      slot="activator"
                      :value="cqDate"
                      :hide-details="true"
                      label="Date Of Issue"
                      class="recp_main_txt"
                      readonly
                    />
                    <VCard class="elevation-20 picker_card">
                      <VDatePicker
                        v-model="cqDateDef"
                        color="primary"
                        no-title
                        scrollable
                        class="elevation-0 pt-2"
                      />
                      <VCardActions>
                        <VSpacer />
                        <div class="pt-2 pr-2 pl-0 pb-2">
                          <div>
                            <VBtn
                              class="ma-0"
                              color="primary"
                              flat
                              round
                              depressed
                              @click="cqDateModal = false"
                            >
                              Close
                            </VBtn>
                            <VBtn
                              class="ma-0"
                              color="primary"
                              flat
                              round
                              depressed
                              @click="$refs.cqDateData.save(cqDateDef)"
                            >
                              Save
                            </VBtn>
                          </div>
                        </div>
                      </VCardActions>
                    </VCard>
                  </VDialog>
                </div>
                <!-- DATE PICKER -->
              </div>
              <div>
                <VTextField
                  v-model="cqBankName"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="State Bank Of India"
                  label="Bank Name"
                />
              </div>
              <div>
                <VTextField
                  v-model="cqBankBranch"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="Barrackpore"
                  label="Bank Branch"
                />
              </div>
              <div>
                <VTextField
                  v-model="cqDepo"
                  :hide-details="true"
                  class="recp_main_txt"
                  placeholder="Camila Cabello"
                  label="Depositor"
                />
              </div>
            </div>
          </VCard>
        </VSlideYTransition>
      </div>
    </section>
    <VDialog
      v-model="isSaving"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'processing'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Saving Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'success'"
        class="notification_card elevation-20"
      >
        <p>Saved</p>
        <span>Receipt Added Successfully</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'savefailed'"
        class="notification_card elevation-20"
      >
        <p>Failed</p>
        <span>Failed To Added Receipt For <strong>{{ stdName }}</strong></span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="snack"
      color="secondary"
      :timeout="5000"
      :multi-line="true"
    >
      {{ snackTxt }}
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snack = false"
      >
        Close
      </VBtn>
    </VSnackbar>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    snack: false,
    snackTxt: '',
    valid: true,
    isSaving: '',
    loadStatus: '',
    rules: {
      email:
        (val) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(val) || 'Invalid e-mail.';
        },
      phoneNumber:
        (val) => {
          const phNoPattern = /[0-9]/;
          return phNoPattern.test(val) || 'Invalid phone number.';
        },
      phoneNumberLimit: val => val.length === 10 || 'Invalid phone number.',
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
    recpDateModal: false,
    recpDateDef: new Date().toISOString().substr(0, 10),
    cqDateModal: false,
    cqDateDef: new Date().toISOString().substr(0, 10),
    admNoInput: '',
    admNoFormat: '###-####',
    recpSessionFormat: '####-####',
    // BASIC
    stdName: '',
    // admission Number => admNo (FLA-123-1920)
    stdClass: '',
    // receipt date => recpDate
    stdPNumber: '',
    recpMonth: '',
    session: '',
    // sessionFormatted
    sessionFormat: '####-####',
    // FEES
    fsTuition: null,
    fsLate: null,
    fsBankCharge: null,
    fsMisc: null,
    // fees total => fsTotal
    fsMethod: 'Cash',
    fsInTxt: '',
    // CHEQUE INFO
    cqNumber: '',
    // Cheque Date => cqDate
    cqBankName: '',
    cqBankBranch: '',
    cqDepo: '',
    selectMethod: ['Cash', 'Cheque'],
    selectClass: [],
    selectMonths: [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ],
  }),
  computed: {
    recpSessionFormatted() {
      const sessionFormatted = `${this.session.substring(0, 4)}-${this.session.substring(4)}`;
      return sessionFormatted;
    },
    admNo() {
      const admInput = this.admNoInput;
      const admNoFormatted = `FLA-${admInput.substring(0, 3)}-${admInput.substring(3)}`;
      return admNoFormatted;
    },

    recpDate() {
      return moment(this.recpDateDef).format('DD-MM-YYYY');
    },
    cqDate() {
      return moment(this.cqDateDef).format('DD-MM-YYYY');
    },
    fsTotal() {
      const fsTotalSum = this.fsTuition + this.fsLate + this.fsBankCharge + this.fsMisc;
      return fsTotalSum.toLocaleString('en-IN');
    },
  },
  async beforeMount() {
    axios.get(`${api}/settings/classes`).then((res) => {
      this.selectClass = res.data.data;
    });
  },
  mounted() {
    // Set Session
    const currentYear = moment().format('YYYY');
    const nextYear = Number(currentYear) + 1;
    this.session = `${currentYear}${nextYear}`;
    // Set Session End
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    save() {
      if (
        this.$refs.form.validate()
        && this.$refs.form1.validate()
      ) {
        const receiptSchema = {
          name: this.stdName,
          admNo: this.admNo,
          class: this.stdClass,
          date: this.recpDate,
          phone: this.stdPNumber,
          month: this.recpMonth,
          session: this.recpSessionFormatted,
          tuitionFee: this.fsTuition,
          lateFee: this.fsLate,
          bankFee: this.fsBankCharge,
          miscFee: this.fsMisc,
          totalFee: this.fsTotal,
          amountInWord: this.fsInTxt,
          method: this.fsMethod,
          cheque: {
            number: this.cqNumber,
            date: this.cqDate,
            bank: this.cqBankName,
            branch: this.cqBankBranch,
            depositor: this.cqDepo,
          },
        };
        this.loadStatus = 'processing';
        this.isSaving = true;
        axios.post(`${api}/receipt/add`, receiptSchema)
          .then((res) => {
            if (res.data.status === 'success') {
              this.loadStatus = 'success';
              setTimeout(() => {
                this.isSaving = false;
                this.$router.push('/receipt');
              }, 2500);
            } else {
              this.loadStatus = 'savefailed';
              setTimeout(() => {
                this.isSaving = false;
              }, 2500);
            }
          }).catch((e) => {
            if (e.code === 'auth/network-request-failed') {
              this.loadStatus = 'netfail';
              setTimeout(() => {
                this.isSaving = false;
              }, 5000);
            } else {
              this.loadStatus = 'fail';
            }
          });
      } else {
        this.snack = true;
        this.snackTxt = 'Please Fill In All The Fields';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Receipt/new';
</style>
